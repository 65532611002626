import {
  type ExperimentGroupName,
  type Experiment,
  EXPERIMENT_GROUP_NAME,
  type ExperimentsState,
} from './coreTypes'

/** 프론트 코드에서 관리하는 실험키 */
const FRONT_DRIVEN_EXPERIMENT_KEY = ['searched_result_back_btn'] as const
export type FrontDrivenExperimentKey =
  (typeof FRONT_DRIVEN_EXPERIMENT_KEY)[number]

type GetAssignedGroupName = ({
  userId,
}: {
  userId: number
}) => ExperimentGroupName

/** 프론트에서 관리하는 실험 정보
 * @property experimentKey 실험 이름
 * @property assignedGroupName 디폴트 실험 그룹
 * @property segments 대조군, 실험군 정보
 * @property getAssignedGroupName 실험그룹(assignedGroupName)을 동적으로 할당할 때 사용
 */
export type FrontDrivenExperimentScheme =
  Experiment<FrontDrivenExperimentKey> & {
    getAssignedGroupName?: GetAssignedGroupName
  }

export type FrontDrivenExperiments = {
  [key in FrontDrivenExperimentKey]: FrontDrivenExperimentScheme
}

export const progressExperiments: FrontDrivenExperiments = {
  searched_result_back_btn: {
    experimentKey: 'searched_result_back_btn',
    assignedGroupName: 'control',
    segments: [
      {
        desc: '있음',
        groupName: 'control',
      },
      {
        desc: '없음',
        groupName: 'treatment1',
      },
    ],
    // getAssignedGroupName: getGroupNameFromUserId(2),
  },
}

export const gerFrontDrivenExperiments = ({ userId }: { userId: number }) => {
  // 배열을 객체로 변환
  const nomalrizedExperiments = Object.entries(progressExperiments).reduce(
    (acc, [key, experiment]) => {
      acc[key as FrontDrivenExperimentKey] = {
        experimentKey: experiment.experimentKey,
        assignedGroupName: experiment.getAssignedGroupName
          ? experiment.getAssignedGroupName({ userId })
          : experiment.assignedGroupName,
        segments: experiment.segments,
      }
      return acc
    },
    {} as ExperimentsState<FrontDrivenExperimentKey>
  )

  return nomalrizedExperiments
}

/**
 * 유저 아이디를 이용해 실험 값을 가져오는 함수를 반환하는 함수
 * @param segmentNum 실험 값의 범위
 */
function getGroupNameFromUserId(segmentNum: number): GetAssignedGroupName {
  // segmentNum을 반드시 실험하려는 실험군 크기에 맞춰서 사용해야 합니다.
  return ({ userId }) => EXPERIMENT_GROUP_NAME[userId % segmentNum]
}
