import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { getSessionStore, setSessionStore } from '@src/store/sessionStore'

const SESSION_STORE_PREV_STEP_KEY = 'homeActivity/prevStep'

export const getPrevStep = () => {
  const prevStep = getSessionStore(SESSION_STORE_PREV_STEP_KEY)

  if (!prevStep) {
    return null
  }

  return prevStep as LocalMapStepKeys
}

export const setPrevStep = (step: LocalMapStepKeys | null) => {
  setSessionStore({
    key: SESSION_STORE_PREV_STEP_KEY,
    value: step ?? '',
  })
}
